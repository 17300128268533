<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" clearable dense :disabled="disabled_region == true"  @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch Office" clearable dense :disabled="disabled_office == true"  @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" label="Salesman" clearable dense :disabled="disabled_salesman == true" prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="typeModel" :items="typeLists" default="" item-value="value" item-text="text" label="Type" @change="(event) => typeOnChange(event)" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="reasonModel" :items="reasonLists" default="" item-value="str1" item-text="str2" label="Reason" clearable dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" type="month" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateStartModel)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" type="month" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateEndModel)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-6 col-6">
                                <!-- <v-btn class="mt-3 mr-3" color="green" elevation="2" small rounded @click="addNewOrderCancel()" dark>Add New<v-icon right dark>mdi-file-plus</v-icon></v-btn> -->
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            <div class="col-lg-12">
                                <div class="row text-left badgeInfo">
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-wallet"></span> IDR Total : {{ sumIdr ? formatNumber(sumIdr) : '0'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-weight-kilogram"></span> Weight Total : {{ sumWgt ? formatNumber2(sumWgt) : '0'}} Tons</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <v-dialog v-model="dialogDetails" max-width="80%">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <!-- <div class="row">
                                                <div class="col-6">
                                                    <v-text-field v-model="searchItem" append-icon="mdi-magnify" class="w-50" label="Search" dense single-line hide-details></v-text-field>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <v-btn class="mt-3 text-white" color="#0D47A1" elevation="2" small dense @click="exportToExcel()">Export to Excel<v-icon right dark>mdi-arrow-down-bold-box</v-icon></v-btn>
                                                </div>
                                            </div> -->
                                            <td class="text-left w-50">
                                                <v-text-field v-model="searchItem" append-icon="mdi-magnify" class="w-50" label="Search" dense single-line hide-details></v-text-field>
                                            </td>
                                            <td class="text-right w-50">
                                                <v-btn class="mt-3 text-white" color="#0D47A1" elevation="2" small dense @click="exportToExcel()">Export Excel<v-icon right dark>mdi-arrow-down-bold-box</v-icon></v-btn>
                                            </td>
                                        </v-toolbar>
                                    </template>
                                    <!-- <template v-slot:[`item.LENGTH`]="{ item }">
                                        {{item.LENGTH + ' m'}} 
                                    </template>
                                    <template v-slot:[`item.WGT`]="{ item }">
                                        {{item.WGT + ' Kg'}} 
                                    </template>
                                    <template v-slot:[`item.SUBTOTAL`]="{ item }">
                                        {{'IDR ' + item.SUBTOTAL}} 
                                    </template> -->
                                </v-data-table>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="mt-2" color="red" elevation="2" small rounded @click="dialogDetails = !dialogDetails" dark>CLOSE</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Missed Order Analysis',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            disabled_region: false,
            officeModel: '',
            officeLists: [],
            disabled_office: false,
            salesModel: '',
            salesLists: [],
            disabled_salesman: false,
            typeModel: '',
            typeLists: [
                {
                    text: 'ALL',
                    value: ''
                },
                {
                    text: 'OPERASIONAL',
                    value: 'O'
                },
                {
                    text: 'ADMINISTRATION',
                    value: 'A'
                }
            ],
            reasonModel: '',
            reasonLists: [],
            dateStartModel: '',
            dateEndModel: '',
            dateStartModal: false,
            dateEndModal: false,
            sumIdr: 0,
            sumWgt: 0,
            dialogDetails: false,
            itemLists: [],
            headers: [
                { text: 'DATE', value: 'DATE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'CUSTOMER', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'OFFICE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'SALESMAN', align: 'left', class: 'primary--text blue lighten-5' },
                // { text: 'PROD. CODE', value: 'PROD_CODE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD. DESC', value: 'PROD_DESC', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'LENGTH', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'QTY', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT(KG).', value: 'WEIGHT', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL', value: 'SUBTOTAL', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'REASON', value: 'REASON', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'REMARK', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'action', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatable: false,
            searchItem: '',
            exportToExcelLists: []
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            $('.cardGraph').hide() 
            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            this.dateEndModel = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/MissedOrderAnalysis`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.reasonLists = res.data.reason

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async getData() {

            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateFromFormatted = new Date(this.dateStartModel).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateToFormatted = new Date(this.dateEndModel).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/MissedOrderAnalysis/getData`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    type: this.typeModel ? this.typeModel : '',
                    reason: this.reasonModel ? this.reasonModel : '',
                    dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                    dateEnd: this.dateToFormatted ? this.dateToFormatted : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.data.length != 0){
                    $('.cardGraph').show()
                    this.renderChart(res.data.data, res.data.data2)
                    this.sumIdr = res.data.totalIDR
                    this.sumWgt = res.data.totalWgt
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    $('.cardGraph').hide()
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        renderChart(data, data2){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                title:{
                    text: "Missed Order Analysis"
                },
                subtitles: [{
                    text: "Click for details",		
                    fontColor: "grey",
                }],
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true,
                    },
                },
                axisY: {
                    title: "IDR",
                    titleFontSize: 24,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    gridColor: "#005f73"
                },
                axisY2: {
                    title: "Tons",
                    titleFontSize: 24,
                    },
                legend: {
                    fontColor: "red",
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                toolTip:{
                    shared:true
                },
                data: [{
                    type: "column",
                    indexLabel: "{y}",
                    click: this.getDetails,
                    name: 'IDR',
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelPlacement: "top",  
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,###",
                    lineColor: "blue",
                    indexLabelFontColor: "blue",
                    // color: "#0f4c5c",
                    dataPoints: data,
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    click: this.getDetails,
                    axisYType: "secondary",
                    name: 'Weight',
                    indexLabelFontSize: 12,
                    showInLegend: true, 
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,###.## T",
                    lineColor: "cyan",
                    indexLabelFontColor: "cyan",
                    dataPoints: data2,
                }]
            })

            chart.render()

        },

        async getDetails(id){

            // console.log(id.dataPoint.label);
            this.$store.dispatch('setOverlay', true)
            this.dialogDetails = true
            this.itemLists = []
            this.exportToExcelLists = []
            this.loadingDatatable = true
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/MissedOrderAnalysis/getDetails`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    type: this.typeModel ? this.typeModel : '',
                    reason: this.reasonModel ? this.reasonModel : '',
                    period: id.dataPoint.label ? id.dataPoint.label : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false
                this.itemLists = res.data.result
                this.exportToExcelLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async regionOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/MissedOrderAnalysis/regionOnChange?region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.salesLists = res.data.sales

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/MissedOrderAnalysis/officeOnChange?region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async typeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/MissedOrderAnalysis/typeOnChange?type=${this.typeModel ? this.typeModel : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.reasonLists = res.data.reason

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {

            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        formatNumber2(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "Missed-Order-Analysis-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>